import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/layout'

import {
  postMeta,
  postHeading,
  excerpt
} from './index.module.css'

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Posts">
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <div className={postHeading}>
              <h2 className>
                <Link to={`/blog/${node.slug}`}>
                  {node.frontmatter.title}
                </Link>
              </h2>
              <span className={postMeta}>{node.frontmatter.date}</span>
            </div>
            <p className={excerpt}>{node.excerpt}</p>
            <Link to={`/blog/${node.slug}`}>
              Read more...
            </Link>
            <span className={postMeta}>{node.timeToRead} min. read</span>
          </article>))
      }
    </Layout>
  )
}

export const query = graphql`
query {
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    nodes {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      id
      slug
      timeToRead
      excerpt
    }
  }
}
`;

export default BlogPage
